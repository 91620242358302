@import '../../styles/abstract.scss';

.authWrapper {
	> h2, > p {
		text-align: center;
	}

	h2 {
		@include media-below(sm) {
			font-size: 24px;
		}
	}

	p {
		margin-top: 5px;
		font-size: 17px;
		line-height: 24px;

		&[class*="error"] {
			font-size: 12px;
			line-height: 130%;
		}

		@include media-below(sm) {
			font-size: 14px;
		}

		+ [class*="callout"] {
			margin-top: 15px;
		}
	}

	a {
		color: inherit;
		font-size: inherit;
	}

	form {
		margin-top: 30px;

		@media(max-width: 320px) {
			margin-top: 10px;
		}
	}

	button[type="submit"] {
		width: 100%;
	}

	[class*="checkbox"] {
		font-size: inherit;
	}

	.notificationText {
		margin-bottom: 35px;
	}
}

.manual {
	width: 100%;
	padding: 12px!important;
}

.back, .toggleLink {
	margin-top: 10px;
}

.thirdpartyAuth {
	position: relative;
	border-top: 1px solid $border-color-light;
	margin-top: 30px;
	padding-top: 30px;

	button {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}

.registerLocked {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 2;
	cursor: pointer;
}

.agreementBox {
	p {
		font-size: 12px;
		line-height: 15px;
		color: $gray-dark;
		margin: 24px 0;
	}

	[class*="checkbox"] {
		label {
			color: $text-gray-light;
		}
	}
}
